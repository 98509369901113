@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.bg-gradient-1 {
    background: rgb(15, 11, 13);
    background: linear-gradient(
        80deg,
        rgba(15, 11, 13, 1) 60%,
        rgba(30, 23, 23, 1) 90%
    );
}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOutLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(-100%);
    }
}

.show-menu {
    animation: slideInLeft 0.3s forwards;
}

.hide-menu {
    animation: slideOutLeft 0.3s forwards;
}


/* BG - #262c2c */
/* gold - #ffe5cf */
/* orange - #fb8557 */
/* green - #42c75e */

/* gray - #3f3f3f */
/* gold - #ecd06f */

/* niebieski - #a6c2e0 */
/* #efefe7 */
/* box-shadow: 0 1px 3px 0 rgba(0,0,0,.15) */
